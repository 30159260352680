import {useRef, useEffect} from 'react'
import {hiddenFields, nonInteractiveFields, updateText, deleteText, exportText, projectManagerEditLayers, globalState} from './constants'

// Digs into the layer, find the attr with the key name, and returns the alias
function replaceAttrWithAlias(attrName, layer){
  for (let field in layer.fields){
    let thisField = layer.fields[field]
    if (thisField.name === attrName){
      // We found a match, return the alias
      return thisField.alias
    }
  }
  // If we didn't find a match, return the original key (should be a fine fallback)
  return attrName
}

// Function to undo the good work we did with the above function
function replaceAliasWithAttr(aliasName, layer){
  for (let field in layer.fields){
    let thisField = layer.fields[field]
    if (thisField.alias === aliasName){
      // We found a match, return the alias
      return thisField.name
    }
  }
  // If we didn't find a match, return the original key (should be a fine fallback)
  return aliasName
}

// Call FeatureLayer.applyEdits() with specified params.
function applyAttributeUpdates(esriRequest, selection, tableTrigger, setTableTrigger, drawingLayer, form, view, paverReg, setPreSelection) {
  return new Promise((resolve, reject) => {
    // Grab updated attributes from the form
    const updated = form.getValues();

    // First loop through all the features being sent
    let editFeatures = []
    for (let feature in selection){
      // We want to deep clone, so stringify and parse first
      let thisFeature = JSON.parse(JSON.stringify(selection[feature]))
      // Drop geometry, to save on bytes sent
      delete selection[feature].geometry
      
      // Sneak in some of our internal logic
      // If this user is not listed as an editor yet, add their name
      let editorText = paverReg.username + " | "
      if (selection[feature]['APIEditor'] && selection[feature]['APIEditor'].indexOf(paverReg.username) === -1){
        thisFeature.attributes['APIEditor'] += editorText
      } else {
        thisFeature.attributes['APIEditor'] = editorText
      }

      // Loop through updated attributes and assign the updated values to feature attributes
      Object.keys(updated).forEach((key) => {
        // If we're supposed to skip, do not update! This will preserve the individual values
        // Also skip if the fields are hidden or not editable
        if (
          hiddenFields.indexOf(key) === -1 &&
          nonInteractiveFields.indexOf(key) === -1 &&
          !thisFeature.skip[replaceAttrWithAlias(key, drawingLayer)]
        ){
          thisFeature.attributes[key] = updated[key]
        }
      })

      // Push into array
      editFeatures.push(thisFeature)
    }

    const updateBtn = document.getElementById("btn-update")
    updateBtn.innerHTML = `<img class="loading" src="/loading.png" />`

    // If we make invisible, it will speed up the edits since it won't have to redraw on each chunk
    drawingLayer.visible = false
    chunkOperation(esriRequest, "updateFeatures", editFeatures, drawingLayer, updateBtn).then((failMsg) => {
      if (failMsg){
        console.log("===============================================");
        console.error(
          "[ applyEdits ] FAILURE: ",
          failMsg
        )
        //console.log("error = ", failMsg);
      }

      updateBtn.innerHTML = `Update complete!`
      // Refresh the table
      //console.log("UPDATE THE tableTrigger")
      let newTrigger = tableTrigger.slice()
      newTrigger.push("update")
      setTableTrigger(newTrigger)

      // Remove the preselect
      // Weird issue, but otherwise the updates may not persist if user goes back to preselect and selects the same choice again
      // By removing it, they'll have to reselect, but the data will be reliable
      if (setPreSelection){
        setPreSelection({})
      }

      // Make visible again
      drawingLayer.visible = true

      // Reset the button text
      setTimeout(() => {
        updateBtn.innerHTML = updateText
      }, 1000)

      // Resolve the promise
      resolve(true)
    })
  })
}

// Function to unselect features
function unselectAll(setSelection, setPreSelection) {
  // We don't hide the form here on PURPOSE! We have a timeout on that for the delete function to leave time to pop the message
  setSelection([])
  // If this is a valid value, that means we're supposed to clear it (sometimes we do something different)
  if (setPreSelection){
    setPreSelection({})
  }
}

function selectIds(token, objects, drawingLayer, key, setPreSelection, editLayers, form, view, Graphic, selectFeatures, setSelection){
  return new Promise((resolve) => {
    // Query features from the server, to get objects we can send to selectFeatures
    // NOTE: Not sure why we need to do this, but using the objects directly results in a form error
    // ArcGIS is great!
    let ids = []
    //console.log("objects", objects, objects.length)
    // Bail if we're busy
    if (document.getElementById("drawing-help")) return

    for (let item in objects){
      // This will either come in as just an ID or as a full ArcGIS feature object, account for both
      let objID = objects[item]
      if (objects[item].attributes){
        objID = objects[item].attributes.OBJECTID
      }
      ids.push(objID)
    }
    // If there's nothing to select, do nothing
    if (ids.length === 0){
      resolve(false)
      return false
    }

    const makeSelectionVisible = () => {
      // Make sure this layer is visible (and the parent!)
      drawingLayer.visible = true
      drawingLayer.parent.visible = true
      // If we're zoomed out further than the layer's visibility, then show only selection from layer
      // TODO: Ian suggests we keep it visible when zooming in
      if (view.scale > drawingLayer.minScale){
        // Save originals so they can be reset
        let originalMin = drawingLayer.minScale
        let originalDef = drawingLayer.definitionExpression
        
        // Make the lines visible even though they are outside the scale
        drawingLayer.minScale = 0
        const idSet = JSON.stringify(ids).replace("[", "(").replace("]", ")")
        drawingLayer.definitionExpression = `OBJECTID IN ${idSet}`

        // Create a watch that checks for zoom once and then retires itself
        let handle = view.watch("scale", function(newValue) {
          // Restore original attributes on zoom
          drawingLayer.minScale = originalMin
          drawingLayer.definitionExpression = originalDef
          handle.remove()
        })
      }
    }

    // Something weird happened, bail
    if (ids.length === 0){
      resolve(false)
      return false
    }
    // Zoom to extent of features
    //console.log("QUERY THE EXT")
    if (ids.length < 20000){
      drawingLayer.queryExtent({
        objectIds: ids
      })
      .then((ext) => {
        view.goTo(ext.extent).then(() => {
          makeSelectionVisible()
        })
      })
    } else {
      // If the id length is too large, DON'T do the extent query -- it will cause the app to fail
      makeSelectionVisible()
    }

    // Handle the select
    getPages(token, drawingLayer, null, ids, "*")
    .then((results) => {
      // Unselect all before applying the new selection
      unselectAll(setSelection)
      // Apply the new selection (preselect will directly select if it only has 1 layer)
      let multiSelectMap = {[key]: results}
      setPreSelection({multiSelectMap, editLayers, form, view, Graphic, selectFeatures})
      resolve(true)
    })
  })
}

// Display the selected feature's attributes in the featureform
// objects should be an array of features
function selectFeatures(objects, drawingLayer, lockAll, form, view, Graphic, selection, setSelection, noRemove) {
  console.log(objects)
  // Nothing to select, bail
  if (objects.length === 0) {
    return new Promise((resolve) => {
      resolve()
    })
  }
  //console.log("LOCK FORM?", lockAll)
  return new Promise((resolve, reject) => {
    if (objects.length > 0) {
      // Make sure we don't do operations on non-existent buttons
      if (!lockAll){
        // Make sure buttons are back in their initial state
        const deleteBtn = document.getElementById("btn-delete")
        deleteBtn.classList.remove("confirm")
        deleteBtn.innerHTML = deleteText
        const updateBtn = document.getElementById("btn-update")
        updateBtn.innerHTML = updateText
        const exportBtn = document.getElementById("btn-export")
        exportBtn.innerHTML = exportText
        // Handle hide/show for the delete button
        deleteBtn.style.display = "block"
        if (drawingLayer.url.indexOf("Alerts") > -1){
          deleteBtn.style.display = "none" // Hide the delete button if we're on the alerts layer
        }
      }
      
      // Check if new selection already exists in the current set! Then it's a removal
      let updatedSelection = selection.slice()
      let updateEl = document.getElementById("update")

      // Iterate through all the selection IDs and deal with them (either remove or add)
      for (let sel in objects){
        let thisSel = objects[sel]
        //console.log("thisSel", thisSel)
        let itemIndex = selection.findIndex((item) => item.attributes.GlobalID === thisSel.attributes.GlobalID)
        if (itemIndex !== -1){ // EXISTS ALREADY, REMOVE IT
          // If we're passing noRemove, just don't touch it
          if (!noRemove){
            // Update selection state
            updatedSelection.splice(itemIndex, 1)
          }
        } else { // DOES NOT EXIST, ADD IT
          // Update selection state
          updatedSelection.push(thisSel)
        }
      }

      // Run the set with the latest array
      //console.log("UPDATED SELECTION", updatedSelection)
      setSelection(updatedSelection)

      if (updatedSelection.length > 0){
        // Clear the existing field set in prep for new push
        form.formTemplate.elements = []
        form.formTemplate.title = "Selected layer: " + drawingLayer.title
        // Make sure the edit layer is the current layer
        form.layer = drawingLayer
        // Now check what should be in the form after the add/remove
        const incomingAttrs = Object.assign({}, updatedSelection[0].attributes)
        let skips = {} // Keep track of anything that could be bypassed
        // Iterate through all the values of "selection" and if there are discrepancies with incoming, make the fields blank
        for (let item in updatedSelection){
          let thisItem = updatedSelection[item]
          // Now iterate through attributes to check for discrepancies
          // console.log("ATTRIBUTES!!", thisItem, thisItem.attributes)
          Object.keys(thisItem.attributes).forEach((key,index) => {
            // We found a mismatch, so let the user know
            if (thisItem.attributes[key] !== incomingAttrs[key] && hiddenFields.indexOf(key) === -1){
              skips[replaceAttrWithAlias(key, drawingLayer)] = "skip"
              // Handle the multiple text differently depending on var type
              if (typeof incomingAttrs[key] === "string"){
                incomingAttrs[key] = ""
              } else {
                // For dates and numbers, just do null to make the field blank
                incomingAttrs[key] = null
              }
            }
            // Make sure skips is saved on this so it can be used in the actual edit function
            thisItem.skip = skips
            // Also populate the fields for the form here
            // Use the first item in selection
            if (item == updatedSelection.length - 1){
              // List exclusions (fields that we want in data but don't want to be seen)
              //console.log("key", key)
              if (hiddenFields.indexOf(key) === -1){
                //console.log("show it!")
                // Create the object
                let newData = {
                  type: "field",
                  fieldName: key,
                  label: replaceAttrWithAlias(key, drawingLayer)
                }
                // For some dates, remove the time
                // console.log("WHAT KEY", key)
                if (["MorEnd", "PaveDate", "UploadDate", "AssessDate", "StartDate", "CreationDate", "EditDate"].indexOf(key) !== -1){
                  newData.input = {
                    type: "datetime-picker",
                    includeTime: false
                  }
                }
                // List read-only fields
                if (nonInteractiveFields.indexOf(key) !== -1 || lockAll){
                  newData.editable = false
                }
                // Push this field into our data set
                form.formTemplate.elements.push(newData)
              }
            }
          });
        }
        const graphicPlaceholder = new Graphic({
          attributes: incomingAttrs
        })

        // Display the attributes of selected feature in the form
        form.feature = graphicPlaceholder

        // Make sure the form is visible if it was hidden
        toggleEditPane(view, true)

        // Remove the multiflag here
        form.container.querySelectorAll('.multi-flag').forEach((node) => {
          node.parentNode.removeChild(node)
        })

        // Now we can iterate through and find the ones that should have the multi flag
        // Need to wait a sec for the form to populate
        setTimeout(() => {
          let labelMod = form.container.querySelectorAll('label').forEach((node) => {
            // If there is a skip registered for this attribute, add the skip element
            let nodeText = node.firstChild.textContent.replace(/\"/g, "").trim()
            if (skips[nodeText]){
              let div = document.createElement("div")
              div.className = "multi-flag"
              div.title = "Keep the field as-is to leave the differing values intact"
              div.innerHTML = "[Multiple values]"
              node.insertBefore(div, node.firstChild.nextSibling)
              // Create an event to clear the flag
              node.querySelectorAll('input').forEach((input) => {
                let focusHandler = input.addEventListener('change', () => {
                  // Only run the remove if it's actually the right event, otherwise it might just be a persisting one that will be removed anyway outside this closure
                  if (node.contains(div)){
                    // Remove the visual flag
                    node.removeChild(div)
                    // Remove the actual flag
                    delete updatedSelection[updatedSelection.length - 1].skip[nodeText]
                  }
                  // Remove event listener
                  input.removeEventListener('change',focusHandler);
                })
              })
            }
          })
        }, 100)
        
      } else { 
        // The selection list is empty! Hide the form
        toggleEditPane(view, false)
      }

      // Return the selection if we ever need it
      resolve(objects)
    } else {
      // We didn't get anything, so return an empty array
      resolve([])
    }
  })
}

function updateVertices(event, view, Graphic) {
  // create a polyline from returned vertices
  if (event.vertices.length > 1) {
    createGraphic(event, view, Graphic)
  }
}

function finishLine(event, view, webMercatorUtils) {
  // Now that we're done, convert these XYs into LngLats
  let newGeo = []
  for (let i = 0; i<event.vertices.length; i++){
    let converted = webMercatorUtils.xyToLngLat(event.vertices[i][0], event.vertices[i][1])
    newGeo.push(converted)
  }
  // Clear graphics when finished
  view.graphics.removeAll()
  // Return newGeo so we can make the new polyline
  return newGeo
}

// create a new graphic presenting the polyline that is being drawn on the view
function createGraphic(event, view, Graphic) {
  const vertices = event.vertices
  view.graphics.removeAll()

  // a graphic representing the polyline that is being drawn
  const graphic = new Graphic({
    geometry: {
      type: "polyline",
      paths: vertices,
      spatialReference: view.spatialReference
    },
    symbol: {
      type: "simple-line", // autocasts as new SimpleFillSymbol
      color: [85, 220, 224],
      width: 2,
      cap: "round",
      join: "round"
    }
  })

  // Just add the graphic representing the polyline
  view.graphics.add(graphic)
}

// Because we are potentially handling thousands of operations, chunk them into micro requests so they don't time out
function chunkOperation(esriRequest, type, editFeatures, drawingLayer, btn, setBatch){
  return new Promise(resolve => {
    // Large edit of content can fail, so chunk this operation into groups
    let arrays = []
    let size = 500
    // We can accept a much larger chunk for deletes, since we only need to pass the OBJECTID
    if (type === "deleteFeatures"){
      size = 1000
    }
    //console.log("WHAT TYPE IS THIS", type, size)
    for (let i = 0; i < editFeatures.length; i += size){
      arrays.push(editFeatures.slice(i, i + size))
    }
    //console.log("HERE ARE OUR ARRAYS", arrays)

    // let wrapPromise = (params) => {
    //   return new Promise(resolve => {
    //     drawingLayer.applyEdits(params).then((editsResult) => {
    //       // Blank is good, resolve this one
    //       resolve("")
    //     })
    //     .catch((error) => {
    //       resolve(error.message)
    //     })
    //   })
    // }

    let queuePromises = async (arrays) => {
      let done = false
      let cursor = 0
      let allResults = []
      // If we received an empty stack, just return empty
      if (arrays.length === 0){
        return allResults
      }

      while (!done){
        let thisChunk = arrays[cursor]
        // Wait for the request
        let editsResult;
        try {
          if (btn){
            btn.innerHTML = `Batch ${cursor+1} of ${arrays.length} <img class="loading" src="/loading.png" />`
          } else if (setBatch){
            setBatch({
              current: cursor+1,
              max: arrays.length
            })
          }
          const postOptUpdate = {}
          postOptUpdate["method"] = "post"
          postOptUpdate["responseType"] = "json"
          let updateURL = drawingLayer.url + "/0"
          if (type === "deleteFeatures"){
            // We can speed up deletes by just sending the IDs
            let deleteIDs = []
            if (thisChunk[0].attributes){
              for (let item in thisChunk){
                let thisItem = thisChunk[item]
                deleteIDs.push(thisItem.attributes.OBJECTID)
              }
            } else {
              // If there's no attributes, that probably means it's just an array of IDs, use them
              deleteIDs = thisChunk
            }
            
            // Update request
            postOptUpdate["query"] = { f: "json", objectIds: deleteIDs }
            updateURL += "/deleteFeatures"
          }
          if (type === "updateFeatures"){
            // We can speed up edits by not sending the geometry
            let editMod = JSON.parse(JSON.stringify(thisChunk))
            for (let item in editMod){
              let thisItem = editMod[item]
              delete thisItem.geometry
            }
            // Update request
            postOptUpdate["query"] = { f: "json", "adds": "", "updates": JSON.stringify(editMod), "deletes": "" }
            updateURL += "/applyEdits"
          }

          let editsResult = await esriRequest(updateURL, postOptUpdate)
          // if (editsResult.data){
          //   // TODO: We would really want to iterate through editsResult.data.updateResults or editsResult.data.deleteResults and make sure all the objects have success: true, or else report back that something went wrong
          //   console.log("IT'S GOOD", editsResult)
          // } else {
          //   console.log("IT'S BAD", editsResult)
          // }
          editsResult = ""
        } catch (err){
          editsResult = err.message
        }
        allResults.push(editsResult)

        // Either finish or continue
        if (cursor === arrays.length - 1){
          done = true 
        } else {
          cursor++
        }
      }
      // Return the array with the responses
      return allResults
    }

    // See if we can send out all the update requests at once to reduce the overall time required
    // let promiseStack = []
    // for (let chunk in arrays){
    //   let thisChunk = arrays[chunk]
    //   console.log("ARRAY LENGTH", arrays.length)
    //   console.log("CHUNK LENGTH", thisChunk.length)

    //   // Setup the applyEdits parameter with updates
    //   const params = {
    //     [type]: thisChunk
    //   }
    //   console.log("PARAMS", params)

    //   wrapPromise(params).then((msg) => {

    //   })
    //   promiseStack.push(newPromise)
    // }
    // let done = false
    // let cursor = 0
    // while (!done){
    //   let thisChunk = arrays[cursor]
    //   // Setup the applyEdits parameter with updates
    //   const params = {
    //     [type]: thisChunk
    //   }
    //   console.log("PARAMS", params)
    //   wrapPromise(params).then((msg) => {

    //   })
    // }

    // queuePromises().then((values) => {

    // })

    //Promise.all(promiseStack).then((values) => {
    queuePromises(arrays).then((values) => {
      let failMsg = ""
      for (let val in values){
        let thisVal = values[val]
        if (thisVal){
          failMsg += thisVal + " ||| "
        }
      }
      // If all good, this should be blank
      resolve(failMsg)
    })
  })
}

function deleteFeatures(esriRequest, selection, drawingLayer, setSelection, setPreSelection, view, orgUpdate){
  const deleteBtn = document.getElementById("btn-delete")
  if (!deleteBtn.classList.contains("confirm")){
    // Make sure they confirm the delete
    deleteBtn.innerHTML = `⚠️&nbsp;&nbsp;Confirm delete&nbsp;⚠️`
    deleteBtn.classList.add("confirm")
  } else {
    // This is a real delete!
    deleteBtn.innerHTML = `<img class="loading" src="/loading.png" />`

    chunkOperation(esriRequest, "deleteFeatures", selection, drawingLayer, deleteBtn).then((failMsg) => {
      if (failMsg){
        console.log("===============================================");
        console.error(
          "[ applyEdits ] FAILURE: ",
          failMsg
        )
        // console.log("error = ", failMsg);
        deleteBtn.innerHTML = `Delete failed!`
        setTimeout(() => {
          deleteBtn.innerHTML = deleteText
        }, 1000)
      } else {
        deleteBtn.innerHTML = `Features deleted!`
        // Remove all values from the React selection var
        unselectAll(setSelection, setPreSelection)
        setTimeout(() => {
          // Uncomment if we want the menu to disappear after
          //document.getElementById("update").classList.add('esri-hidden')
          toggleEditPane(view, false)
          orgUpdate()
        }, 1000)
      }
    })
  } 
}

function addMapButton({id, title, icon, clickFunc, view}){
  let newButton = document.createElement('div')
  newButton.id = id
  newButton.className = "esri-widget esri-widget--button esri-widget esri-interactive"
  newButton.title = title
  newButton.innerHTML = `
    <span class="select-rect"><img src="${icon}"/></span>
  `
  newButton.addEventListener("click", clickFunc)
  view.ui.add(newButton, "top-left")
}

function setLocalStorage(storageID, property, value){
  let storageResult = getLocalStorage(storageID, property, true)
  // Starting fresh
  if (!storageResult){
    storageResult = {}
  }
  storageResult[property] = value
  localStorage.setItem(storageID, JSON.stringify(storageResult))
}

function getLocalStorage(storageID, property, isSet){
  // Update in localStorage
  let storageItem = localStorage.getItem(storageID)
  if (storageItem){
    try {
      // Attempt to parse JSON -- if it breaks, we start fresh
      storageItem = JSON.parse(storageItem)
    } catch (err){
      // It's ok
      storageItem = null
    }
  }  
  // We didn't get anything, return null
  if (!storageItem){
    return null
  } else {
    // Return what we got
    if (isSet){
      return storageItem // We need the whole object if this is actually a set
    }
    return storageItem[property] // Return just the value for the normal get
  }
}

function clearUI(){
  // Hide the layers if they are out
  document.querySelector("#layerlist-wrapper").classList.add("hidden-override")
  document.querySelector("#basemap-wrapper").classList.add("hidden-override")
  document.querySelector("#toggle-layers").classList.remove("show")
  document.querySelector("#basemap-gallery").classList.remove("show")
}

function toggleEditPane(view, show){
  if (show){
    document.getElementById("update").classList.remove('esri-hidden')
    view.padding = {right: 300}
    clearUI()
  } else {
    document.getElementById("update").classList.add('esri-hidden')
    view.padding = {right: 100}
  }
}

// Returns a promise until all results are ready, then returns those
function getPages(token, layer, where, objectIds, outFields, geometry, onlyIds, returnGeometry=false){
  // Flip this global true for the duration (unless it gets canceled)
  globalState.selectionInProgress = true
  // If we're dealing with layerView, get the subset first
  let layerViewPromise = new Promise((resolveInit, rejectInit) => {
    if (layer.layer){  // True if layerView
      layer.queryFeatures().then((output) => {
        let idArray = []
        for (let item in output.features){
          idArray.push(output.features[item].attributes.OBJECTID)
        }
        // Reset layer so we can use it later
        layer = layer.layer
        // Send the ids back out
        resolveInit(idArray)
      })
    } else {
      // This isn't a layerView query so return null
      resolveInit(null)
    }
  })

  let handlePage = (page, cursor, resolve, prevQueryFeatures, subset) => {
    // Limit for how many results we can get per request, higher is faster but it's possible to fail out
    let queryLimit = 2000
    let allQueryFeatures = []
    if (prevQueryFeatures) {
      allQueryFeatures = prevQueryFeatures
    }
    //console.log("GROWING LEN", allQueryFeatures.length, prevQueryFeatures)
    //console.log("cursor", cursor)

    // Handle if it's a standard layer
    const formData  = new FormData()
    formData.append("token", token)
    if (!onlyIds){
      // Only limit if we're not making an ID request (since those return the full set)
      formData.append("resultOffset", 0 + (queryLimit * page))
      formData.append("resultRecordCount", queryLimit)
    }

    // If we aren't getting objectIds from the outside and we get a subset from the layerView, use that instead
    if (!objectIds && subset){
      objectIds = subset
    }

    // Slice the IDs so we only send the IDs we need back for this req
    // The fact that we had to invent an alternative form of pagination is gross, but sending the full set can overwhelm ArcGIS at > 10k features, so we'll do it this way
    let slicedObjectIds = []
    if (cursor === null){
      cursor = -1 // If cursor is -1, we aren't using it
    }
    let cursorDone = false
    if (objectIds){
      cursor++ // Use the cursor
      let cursorMin = queryLimit * cursor
      let cursorMax = cursorMin + queryLimit
      slicedObjectIds = objectIds.slice(cursorMin, cursorMax)

      // If the max is past the length, this should be the last run
      if (cursorMin >= objectIds.length){
        cursorDone = true
      }
    }

    if (cursorDone){
      // If we were iterating with the cursor but we're done now, resolve it
      resolve(allQueryFeatures)
    } else {
      // We're either not done with the cursor or we're using traditional pagination
      if (where){
        formData.append("where", where)
      }

      if (objectIds && !onlyIds){
        formData.append("objectIds", slicedObjectIds)
      }

      if (onlyIds){
        formData.append("returnIdsOnly", true)
      }

      // console.log("outFields", outFields)
      if (outFields){
        formData.append("outFields", outFields)
      } else {
        formData.append("outFields", "OBJECTID")
      }
      
      if (geometry){
        formData.append("geometryType", "esriGeometryPolygon")
        formData.append("geometry", JSON.stringify(geometry))
      }

      formData.append("returnGeometry", returnGeometry)

      // Make the query
      fetch(layer.url + `/0/query?f=json`, {
        method: "POST",
        body: formData
      }).then(response => response.json()).then((output) => {
        //console.log("JUST OUTPUT", output)
        if (output.features){
          // Add features to total
          //console.log("pageResp!", output, output.features.length)
          allQueryFeatures = allQueryFeatures.concat(output.features)

          // Check if we need to loop or finish
          //console.log("pageResp.exceededTransferLimit", output.exceededTransferLimit);
          if (output.exceededTransferLimit || cursor !== -1){
            // If we are prepped for another loop, let's do that
            // Force page to 0 on next run if we are using cursor
            if (cursor !== -1){
              page = -1
            }
            if (globalState.selectionInProgress){
              handlePage(page + 1, cursor, resolve, allQueryFeatures, subset)
            } else {
              // If it ended early, resolve with empty
              resolve([])
            }
          } else {
            // If we're done paginating, resolve
            //console.log("COOL NOW WE CAN RESOLVE")
            if (globalState.selectionInProgress){
              resolve(allQueryFeatures)
            } else {
              // If it ended early, resolve with empty
              resolve([])
            }
          }
        } else if (output.objectIds){
          // If we just requested objectIds, return them
          //console.log("COOL RESOLVE WITH IDS")
          resolve(output.objectIds)
        } else {
          // If this resolved in an error, there won't be any features array, just return an empty array
          //console.log("UH WHAT?")
          resolve([])
        }
      })
    }
  }

  return new Promise((resolve, reject) => {
    layerViewPromise.then((subset) => {
      // Use subset to restrict query to just those in layerView
      handlePage(0, null, resolve, null, subset)
    })
  })
}

// // Like getPages, but just returns the ids (so it's a lot faster!)
// function getItemNumber(token, layer, where, objectIds, outFields, geometry){
//   // If we're dealing with layerView, get the subset first
//   let layerViewPromise = new Promise((resolveInit, rejectInit) => {
//     if (layer.layer){  // True if layerView
//       layer.queryFeatures().then((output) => {
//         let idArray = []
//         for (let item in output.features){
//           idArray.push(output.features[item].attributes.OBJECTID)
//         }
//         // Reset layer so we can use it later
//         layer = layer.layer
//         // Send the ids back out
//         resolveInit(idArray)
//       })
//     } else {
//       // This isn't a layerView query so return null
//       resolveInit(null)
//     }
//   })

//   let handlePage = (page, cursor, resolve, prevQueryFeatures, subset) => {
//     // Limit for how many results we can get per request, higher is faster but it's possible to fail out
//     let queryLimit = 2000
//     let allQueryFeatures = []
//     if (prevQueryFeatures) {
//       allQueryFeatures = prevQueryFeatures
//     }

//     // Handle if it's a standard layer
//     const formData  = new FormData()
//     formData.append("token", token)
//     formData.append("returnIdsOnly", true)

//     // If we aren't getting objectIds from the outside and we get a subset from the layerView, use that instead
//     if (!objectIds && subset){
//       objectIds = subset
//     }

//     // We're either not done with the cursor or we're using traditional pagination
//     if (where){
//       formData.append("where", where)
//     }
    
//     if (geometry){
//       formData.append("geometryType", "esriGeometryPolygon")
//       formData.append("geometry", JSON.stringify(geometry))
//     }

//     // Make the query
//     fetch(layer.url + `/0/query?f=json`, {
//       method: "POST",
//       body: formData
//     }).then(response => response.json()).then((output) => {
//       console.log("JUST OUTPUT", output)
//       if (output.features){
//         // Add features to total
//         console.log("pageResp!", output, output.features.length)
//         allQueryFeatures = allQueryFeatures.concat(output.features)

//         // Check if we need to loop or finish
//         console.log("pageResp.exceededTransferLimit", output.exceededTransferLimit);
//         if (output.exceededTransferLimit || cursor !== -1){
//           // If we are prepped for another loop, let's do that
//           // Force page to 0 on next run if we are using cursor
//           if (cursor !== -1){
//             page = -1
//           }
//           handlePage(page + 1, cursor, resolve, allQueryFeatures, subset)
//         } else {
//           // If we're done paginating, resolve
//           resolve(allQueryFeatures)
//         }
//       } else {
//         // If this resolved in an error, there won't be any features array, just return an empty array
//         resolve([])
//       }
//     })
//   }

//   return new Promise((resolve, reject) => {
//     layerViewPromise.then((subset) => {
//       // Use subset to restrict query to just those in layerView
//       handlePage(0, null, resolve, null, subset)
//     })
//   })
// }


// Creates new table every time we need it
function createNewTable(paverReg, layer, itemArray, latestSelection, setSelection, Graphic, FeatureLayer, FeatureTable, form, view, editLayers, selectKey, setPreSelection, lockAll, setSelectLoading){
  // Lock editing if the user is not a creator
  if (paverReg.role === "Viewer" || (paverReg.role === "Project Manager" && projectManagerEditLayers.indexOf(layer.title) === -1)){
    lockAll = true
    //console.log("LOCK EM UP", paverReg.role, projectManagerEditLayers, layer.title)
  }

  //console.log("LATEST TABLE SELECT", latestSelection, layer)
  // Create the DOM element to connect the table
  let wrapper = document.getElementById("table-wrapper")
  wrapper.innerHTML = `<div id="table-wrapper-inner"></div>`
  // Create a layer to feed into the table
  // If it's more than 2000 records, we can cut it off there -- for really big selections, it will cause the table to fail
  itemArray = itemArray.slice(0, 2000)
  let newExpression = "OBJECTID in ("+itemArray.join(",")+")"
  let tablelayer = new FeatureLayer({
    url: layer.url,
    definitionExpression: newExpression
  });

  tablelayer.on("edits", function(changes) {
    //console.log("EDIT!!!!!", changes)
    // We we detect something has changed AND the form window is still open, we made an edit on the table -- reboot the form template so we can reflect those changes
    if (changes.updatedFeatures.length > 0 && latestSelection.length > 0){
      //console.log("WE MADE A CHANGE ON THE TABLE!!!!!!!!!!!")
      // Loop through latestSelection and extract JUST the OBJECTIDs and pass the array into this function
      let newArray = []
      for (let item in latestSelection){
        newArray.push(latestSelection[item])
      }
      setSelectLoading(newArray.length)
      selectIds(paverReg.token, newArray, layer, selectKey, setPreSelection, editLayers, form, view, Graphic, selectFeatures, setSelection).then((result) => {
        setSelectLoading(false)
      })
      // unselectAll(setSelection)
      // selectFeatures(newArray, layer, form, view, Graphic, latestSelection, setSelection, true)
    }
  })

  // Push in OBJECTID so we have a helpful guide as the table gets huge
  let newFields = []
  // Get first item and create fieldConfig from those attrs
  Object.keys(latestSelection[0].attributes).forEach((key) => {
    // Only show fields if they aren't hidden (at the top of this file)
    if (hiddenFields.indexOf(key) === -1){
      let newField = {
        name: key,
        label: replaceAttrWithAlias(key, layer)
      }
      // Lock this field for non interactive
      // Also lock everything if this selection includes non-member features
      if (nonInteractiveFields.indexOf(key) !== -1 || lockAll){
        newField.editable = false
      }
      newFields.push(newField)
    }
  })
  // Add OBJECTID at the end
  newFields.push({
    name: "OBJECTID",
    label: "Object Id",
    editable: false,
    width: 60 // This does not work, sadly
  })
  // Create the feature table
  let featureTable = new FeatureTable({
    view: view,
    layer: tablelayer,
    editingEnabled: true,
    visibleElements: {
      header: false,
      selectionColumn: false
    },
    // menuConfig: {
    //   items: [{
    //     label: "Zoom to feature(s)",
    //     iconClass: "esri-icon-zoom-in-magnifying-glass",
    //     clickFunction: function(event) {
    //       console.log("HELLO!")
    //       //zoomToSelectedFeature();
    //     }
    //   }]
    // },
    // Autocast the FieldColumnConfigs
    fieldConfigs: newFields,
    container: document.getElementById("table-wrapper-inner")
  })

  // NONE OF THIS WORKS BUT IT'S OK
  // featureTable.when(() => {
  //   console.log("featureTable", featureTable, featureTable.columns, featureTable.columns.items)
  //   featureTable.columns.items[0].width = 60
  //   //document.querySelector('vaadin-grid-column[header="ID"]').style.width = "60px"
  // })

  //console.log("HERE'S THE FEATURE TABE", featureTable, newFields)
  return {tablelayer, featureTable}
}

function useWhyDidYouUpdate(name, props) {
  //console.log("useWhyDidYouUpdate set")
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();
  useEffect(() => {
    //console.log("useWhyDidYouUpdate triggered")
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

function hideOnClickOutside(element, removeFunc) {
  const outsideClickListener = event => {
    //console.log("DTA CLICK")
    if (element){
      if (!element.contains(event.target)) {
        // We clicked outside! Hide this
        removeFunc()
        removeClickListener()
      }
    } else {
      // Element doesn't exist anymore, just remove
      removeClickListener()
    }
  }

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener)
  }

  document.addEventListener('click', outsideClickListener)
}


export {applyAttributeUpdates, selectFeatures, selectIds, unselectAll, updateVertices, finishLine, deleteFeatures, createNewTable, replaceAttrWithAlias, replaceAliasWithAttr, addMapButton, setLocalStorage, getLocalStorage, toggleEditPane, getPages, useWhyDidYouUpdate, hideOnClickOutside, clearUI, chunkOperation}